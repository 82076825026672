/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable no-var, import/order */

import STAGING from './STAGING.js'
import PRODUCTION from './PRODUCTION.js'
import TESTING from './TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_JEST from './IS_JEST.js'
import IS_NODE from './IS_NODE.js'

var environment = 'development'

if (IS_JEST) {
  environment = TESTING
} else if (IS_BROWSER) {
  if (window.WAITERIO_ENV) {
    environment = window.WAITERIO_ENV
  } else if (window.location.hostname.indexOf('staging') !== -1) {
    environment = STAGING
  } else {
    let hostname = window?.location?.hostname || ''
    let splits = hostname.split('.')
    let last = splits[splits.length - 1]
    if (
      last === 'com' ||
      last === 'io' ||
      last === 'cloud' ||
      last === 'style' ||
      last === 'support'
    ) {
      environment = PRODUCTION
    }
  }
} else if (IS_NODE && process.env.WAITERIO_ENV) {
  environment = process.env.WAITERIO_ENV
} else if (IS_NODE && process.env.NODE_ENV) {
  environment = process.env.NODE_ENV
}

export default environment
