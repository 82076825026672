/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'
import setSessionCreationTime from './setSessionCreationTime.js'
import setSessionId from './setSessionId.js'
import setSessionLastEditTime from './setSessionLastEditTime.js'

export default function refreshSession() {
  let _id = uuid()
  let creationTime = new Date().getTime()
  let lastEditTime = creationTime

  setSessionId(_id)
  setSessionCreationTime(creationTime)
  setSessionLastEditTime(lastEditTime)

  return {
    _id,
    creationTime,
    lastEditTime,
  }
}
