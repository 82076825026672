/* Copyright 2013 - 2024 Waiterio LLC */
import getSessionCreationTime from './getSessionCreationTime.js'
import getSessionId from './getSessionId.js'
import getSessionLastEditTime from './getSessionLastEditTime.js'
import refreshSession from './refreshSession.js'

export default function getSession() {
  let _id = getSessionId()
  let creationTime = getSessionCreationTime()
  let lastEditTime = getSessionLastEditTime()

  let now = new Date().getTime()
  if (
    !_id ||
    !creationTime ||
    !lastEditTime ||
    now > creationTime + 24 * 60 * 60 * 1000 ||
    now > lastEditTime + 30 * 60 * 1000
  ) {
    return refreshSession()
  } else {
    return {
      _id,
      creationTime,
      lastEditTime,
    }
  }
}
