/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import setCookie from '@monorepo/shared/setCookie.js'
import product from '@metricsmage/shared/product.js'

export default function setSessionId(sessionCreationTime) {
  setCookie(product + 'SessionCreationTime', sessionCreationTime)
  return localStorage.setItem(
    product + 'SessionCreationTime',
    sessionCreationTime,
  )
}
