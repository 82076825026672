/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable prefer-rest-params, func-names */
import { record } from 'rrweb'
import getApiUrl from '@metricsmage/shared/getApiUrl.js'
import getSession from './session/getSession.js'
import refreshSessionLastEditTime from './session/refreshSessionLastEditTime.js'

// console.log('beginning of MetricsMage script.js')

window.metricsmage = window.metricsmage || []

function postEvent(event) {
  // console.log('event', event)
  return fetch(getApiUrl() + '/api/events', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: window.metricsmage.userId,
      websiteId: window.METRICSMAGE_WEBSITE_ID,
      ...event,
    }),
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(error => {
          throw error
        })
      } else {
        return response.json()
      }
    })
    .catch(console.error)
}

function postSession(session) {
  // console.log('session', session)
  return fetch(getApiUrl() + '/api/sessions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: window.metricsmage.userId,
      websiteId: window.METRICSMAGE_WEBSITE_ID,
      ...session,
    }),
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(error => {
          throw error
        })
      } else {
        return response.json()
      }
    })
    .catch(console.error)
}

function event(name, metadata) {
  return postEvent({
    metadata,
    name,
  })
}

function pageview() {
  let { _id: sessionId } = getSession()
  refreshSessionLastEditTime()
  return postEvent({
    name: 'pageview',
    referrer: document.referrer,
    sessionId,
    url: window.location.href,
  })
}

function identify(userId, metadata) {
  let { _id: sessionId } = getSession()
  refreshSessionLastEditTime()
  window.metricsmage.userId = userId
  return postEvent({
    name: 'identify',
    sessionId,
    userId,
    metadata,
  })
}

pageview()

if (window.METRICSMAGE_SESSION_REPLAYS) {
  const stopRecording = record({
    emit(record) {
      refreshSessionLastEditTime()
      let { _id, creationTime, lastEditTime } = getSession()
      return postSession({
        _id,
        creationTime,
        lastEditTime,
        record,
      })
    },
  })
}

// window.addEventListener('popstate', event => {
//   console.log('popstate with event', event)
// })

if (window.history && window.history.pushState) {
  let pushStateOriginal = window.history.pushState
  window.history.pushState = function () {
    pushStateOriginal.apply(this, arguments)
    // console.log('pushState with arguments', arguments)
    pageview()
  }
}

// document.addEventListener(
//   'visibilitychange',
//   () => {
//     // Don't send analytics if it's a bot
//     if (
//       window.phantom ||
//       window._phantom ||
//       window.__nightmare ||
//       window.navigator.webdriver ||
//       window.Cypress
//     ) {
//       return
//     }
//     if (document.visibilityState === 'visible') {
//       console.log('visible')
//     } else {
//       console.log('hidden')
//     }
//   },
//   {
//     passive: true,
//   },
// )

window.metricsmage.event = event
window.metricsmage.identify = identify

for (let i = 0; i < window.metricsmage.length; i += 1) {
  let parameters = window.metricsmage[i]
  event(parameters)
}

window.metricsmage = new Proxy(window.metricsmage, {
  set(target, prop, value) {
    // console.log('ProxyHandler set', { target, prop, value })
    if (prop === 'userId' || prop === 'length') {
      target[prop] = value
    } else if (Array.isArray(value)) {
      let name = value[0]

      let parameters = value.slice(1)
      // console.log({ name, parameters })
      if (name === 'identify') {
        identify(...parameters)
      } else if (name === 'pageview') {
        pageview(...parameters)
      } else if (name === 'event') {
        event(...parameters)
      } else {
        event(name, ...parameters)
      }
      // console.log('Proxy posted event', value)
    } else {
      target[prop] = value
    }

    return true
  },
})
